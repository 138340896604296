/* Generel styling for appen */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f4f4f9;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 600px;
  margin: 50px auto;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: relative;
}

/* Titelstyling */
.title {
  text-align: center;
  color: #333;
  font-size: 2.5rem;
  margin-bottom: 30px;
  font-weight: bold;
}

/* Kort styling for input og resultater */
.card {
  background-color: #ffffff;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

/* Input label styling */
.label {
  display: block;
  margin-bottom: 8px;
  font-size: 1.1rem;
  color: #555;
}

/* Styling for subtext (kursiv, mindre tekststørrelse, lys grå farve) */
.subtext {
  font-size: 0.85rem; /* Mindre skriftstørrelse */
  color: #777; /* Lysere grå farve */
  font-style: italic; /* Kursiv tekst */
  display: inline-block;
  margin-left: 10px;
}

/* Input felt styling */
.input {
  width: calc(100% - 35px);
  padding: 12px 15px;
  margin-bottom: 15px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Knap styling */
.button {
  width: 100%;
  padding: 12px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.button.primary {
  background-color: #007bff;
  color: white;
}

.button.primary:hover {
  background-color: #0056b3;
}

/* "Gem Beregning" knap - Grøn farve */
.button.save {
  background-color: #28a745; /* Grøn farve */
  color: white;
}

.button.save:hover {
  background-color: #218838;
}

/* "Rediger Faste Værdier" knap - Lys blå farve */
.button.secondary {
  background-color: #87cefa; /* Lys blå farve */
  color: white;
}

.button.secondary:hover {
  background-color: #7ec0ee; /* Hover-effekt med en mørkere blå nuance */
}

/* Historik knap */
.button.history {
  background-color: #ffc107;
  color: white;
}

.button.history:hover {
  background-color: #e0a800;
}

/* Sletteknap - Rød styling */
.button.delete {
  background-color: #dc3545;
  color: white;
  padding: 8px 15px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  width: fit-content;
}

.button.delete:hover {
  background-color: #c82333;
}

/* Knap-gruppe layout */
.button-group {
  display: flex;
  justify-content: space-between;
}

/* Modal styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
}

.modal-title {
  text-align: center;
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
}

/* Luk knap til modal */
.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

/* Modal specifik label og input styling */
.modal-label {
  display: block;
  margin-bottom: 8px;
  font-size: 1rem;
  color: #555;
}

.modal-input {
  width: calc(100% - 35px);
  padding: 12px 15px;
  margin-bottom: 20px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 8px;
  transition: border 0.3s ease;
}

.modal-input:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Modal knap styling */
.modal-button {
  width: 100%;
  padding: 12px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.modal-button.save {
  background-color: #28a745;
  color: white;
}

.modal-button.save:hover {
  background-color: #218838;
}

.modal-button.close {
  background-color: #dc3545;
  color: white;
}

.modal-button.close:hover {
  background-color: #c82333;
}

/* Historik container styling */
.history-container {
  max-height: 80vh; /* Justeret for at passe til større modal */
  overflow-y: auto;
  width: 100%;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.history-item {
  background-color: #ffffff;
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}

.history-row {
  margin-bottom: 8px;
  font-size: 1rem;
  color: #333;
}

.history-row strong {
  color: #007bff;
}

/* Knap til at skifte bruger inde i containeren */
.user-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  width: fit-content;
}

.user-button:hover {
  background-color: #0056b3;
}

/* Specifik styling for historik modalvindue */
.history-modal {
  max-height: 90vh; /* Øg maksimal højde til 90% af viewport-højden */
  max-width: 800px; /* Øg maksimal bredde til 800px */
  width: 90%; /* Sæt bredden til 90% af viewport-bredden */
}

.alarm {
  background-color: #ffcccc;
  color: #d8000c;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
}
